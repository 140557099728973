import React from "react";
import Navbar from "../../components/Navbar";

const PrivacyPolicy = () => {
  return (
    <div style={{ height: "100%" }}>
      <Navbar />
      <div
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "#68978c",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            height: "100%",
            width: "100%",
            padding: "2rem",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "10%",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <h2 style={{ textAlign: "center" }}>Privacy Policy</h2>
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p>
              Carrion Crew and Exo-Suit Trials are games designed for use on android and ios
              devices.
            </p>
            <p>
              This Privacy Policy explains what personal data may be collected
              while playing this game. While we do not collect, store or process
              user data, third party advertisers do. By providing us with your
              personal data when playing the game, you consent to the processing
              of your personal data as described in this Privacy Policy. If you
              do not agree to this Privacy Policy, please do not play this game.
            </p>
            <h5>Personal Data</h5>
            <p>We do not collect, store or process data</p>
            <p>
              Unity Ads is used to serve ads to users. Please refer to their
              Privacy Policy for more information:
              https://unity.com/legal/privacy-policy
            </p>
            <h5>Changes to our Privacy Policy</h5>
            <p>
              We may change this Privacy Policy at any time and when we do we
              will post and updated version on this page. By continuing to use
              play the game, you consent to the revised Privacy Policy and
              practices described in it.
            </p>
            <h5>Contact Us</h5>
            <p>
              If you have any questions about our Privacy Policy or our data
              practices, please contact us.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
