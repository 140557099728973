import React from "react";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import { FiTwitter } from "react-icons/fi";
import styles from "./styles.module.css";

const Footer = ({ scrollLinks }) => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerGrid}>
        <div className={styles.footerRow}>
          <p className={styles.footerTitle}>PicSaw Games</p>
          <LinkR to="/privacy-policy" className={styles.footerText}>
            Privacy Policy
          </LinkR>
          <LinkR to="/terms-and-conditions" className={styles.footerText}>
            Terms and Conditions
          </LinkR>
          {/* <p className={styles.footerText}>Address: 123 Fake Street</p> */}
        </div>
        <div className={styles.footerRow}>
          <p className={styles.footerTitle}>Sign Up</p>
        </div>
        <div className={styles.footerRow}>
          <p className={styles.footerTitle}>Navigation</p>
          <div
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              flex: 1,
            }}
          >
            {scrollLinks.map((scrollLink) => (
              <LinkS
                to={scrollLink}
                smooth
                spy
                spyThrottle={500}
                duration={500}
                className={styles.footerLink}
              >
                <p className={styles.footerLinkText}>{scrollLink}</p>
              </LinkS>
            ))}
          </div>
        </div>
        <div className={styles.footerRow}>
          <p className={styles.footerTitle}>Social Media</p>
          {/* <div>
            <a
              href="https://twitter.com/seagullsight"
              target="_blank"
              rel="noreferrer"
            >
              <FiTwitter size="4em" />
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
