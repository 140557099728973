import React from "react";
import styles from "./styles.module.css";

import AppStoreButton from "../AppStoreButton";

const Main = () => {
  return (
    <div className={styles.background}>
      <div className={styles.titleContainer}>
        <h1 className={styles.text}>PicSaw Games</h1>
        <h3 className={styles.text}>Contact me at: seagullsight@yahoo.com</h3>
      </div>
    </div>
  );
};

export default Main;
