import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Home";

import TermsAndConditions from "../TermsAndConditions";
import PrivacyPolicy from "../PrivacyPolicy";

import { SemanticToastContainer } from "react-semantic-toasts";
import styles from "./styles.module.css";

function App() {
  return (
    <Router>
      <SemanticToastContainer position="top-right" className={styles.toast} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
