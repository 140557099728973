import React from "react";
import imagePic from "../../assets/svg1.svg";
import styles from "./styles.module.css";

const About = ({
  reverse = false,
  header = "",
  description = "",
  additional = "",
  image = { imagePic },
}) => {
  return (
    <div className={styles.aboutContainer} name="about">
      <div className={styles.aboutGrid}>
        {reverse ? (
          <div className={styles.imageContainer}>
            <img
              src={image.imagePic}
              className={styles.image}
              alt="something"
            />
          </div>
        ) : null}
        <div className={styles.descriptionContainer}>
          <h2 className={styles.title}>{header}</h2>
          <h3 className={styles.subtitle}>{description}</h3>
          <p className={styles.paragraph}>{additional}</p>
        </div>
        {reverse ? null : (
          <div className={styles.imageContainer}>
            <img
              src={image.imagePic}
              className={styles.image}
              alt="something"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default About;
