const contentConfig = [
  {
    reverse: true,
    header: "Carrion Crew",
    description:
      "Multiplayer Action Game",
    additional:
      "Team up with other players to take on enemy ships.",
    //image: { imagePic: CoordinateImage },
  },
  {
    reverse: false,
    header: "Exo-Suit Trials",
    description: "2D Runner Game",
    additional:
      "Evade obstacles and destroy crates in a secret warehouse complex to help calibrate a cutting-edge Exo-Suit. Compare your highscore with others.",
    //image: { imagePic: ShareImage },
  }
];

export default contentConfig;
