import React from "react";
import About from "../../components/About";
import Main from "../../components/Main";
import Services from "../../components/Services";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import contentConfig from "../../contentconfig";

const Home = () => {
  return (
    <div style={{ flex: 1 }}>
      {/* <Navbar scrollLinks={["about"]} /> */}
      <Main />
      {contentConfig.map((config) => {
        const { reverse, header, description, additional, image } = config;
        return (
          <About
            reverse={reverse}
            header={header}
            description={description}
            additional={additional}
            image={image}
          />
        );
      })}
      {/* <Services /> */}
      <Footer scrollLinks={["about"]} />
    </div>
  );
};

export default Home;
