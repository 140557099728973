import React from "react";
import Navbar from "../../components/Navbar";

const TermsAndConditions = () => {
  return (
    <div style={{ height: "100%" }}>
      <Navbar />
      <div
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "#68978c",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            height: "100%",
            width: "100%",
            padding: "2rem",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "10%",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <h2 style={{ textAlign: "center" }}>Terms and Conditions</h2>
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              height: "90%",
            }}
          >
            <p>
              By playing Exo-Suit Trials, you agree to and are bound to the
              Terms and Conditions herein, and agree to the Privacy Policy.
              Whenever you use the service, you confirm that you have read both
              documents and understand them.
            </p>

            <h5>Advertising</h5>
            <p>
              Third party ads may be presented on the application. We are not
              responsible for the content, messages or material therein. We are
              not liable for any losses or damages incurred as a result of your
              participation with there Ad providers.
            </p>

            <p>
              The Terms and Conditions and Privacy Policy may change in the
              future. By continuing to access the service, you agree to any
              updated Terms.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
