import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { scroller } from "react-scroll";
import { Link } from "react-router-dom";

const SideBar = ({ sideBarOpen, setSideBarOpen, scrollLinks = [] }) => {
  const scroll = (location) => {
    scroller.scrollTo(location, {
      smooth: true,
      spy: true,
      duration: 500,
    });
    setSideBarOpen(!sideBarOpen);
  };

  return (
    <div
      style={{
        display: "grid",
        height: "100%",
        width: "100%",
        gridTemplateColumns: "1fr 1fr 1fr",
        backgroundColor: "#444444",
        position: "fixed",
        top: sideBarOpen ? 0 : "-100%",
        opacity: sideBarOpen ? "100%" : "0",
        zIndex: 999,
        transition: "0.5s ease-in-out",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignContent: "center",
          alignItems: "center",
          gridColumnStart: 2,
        }}
      >
        {scrollLinks.map((scrollLink) => (
          <p onClick={() => scroll(scrollLink)} style={{ color: "blue" }}>
            {scrollLink}
          </p>
        ))}
        {/* <Link
          to="/account"
          onClick={() => setSideBarOpen(!sideBarOpen)}
          style={{ color: "blue" }}
        >
          Account/Sign In
        </Link> */}
      </div>
      <div
        style={{
          display: "grid",
          justifySelf: "flex-end",
          alignSelf: "flex-start",
          padding: "20px",
        }}
      >
        <div>
          <AiOutlineCloseCircle
            onClick={() => setSideBarOpen(!sideBarOpen)}
            style={{ color: "blue" }}
            size={28}
          />
        </div>
      </div>
    </div>
  );
};

export default SideBar;
