import React, { useState } from "react";
import { Link as LinkS, animateScroll } from "react-scroll";
import { Link as LinkR } from "react-router-dom";
import { FiMenu } from "react-icons/fi";

import styles from "./styles.module.css";
import SideBar from "../SideBar";

const Navbar = ({ scrollLinks = [] }) => {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const toggleHome = () => {
    animateScroll.scrollToTop({ smooth: true });
  };
  // list of items for scroll links
  return (
    <div>
      <div className={styles.navContainer}>
        <nav className={styles.nav}>
          {/* <LinkR to="/" className={styles.pageLink} onClick={toggleHome}>
            <img
              src={SeagullSightLogo}
              style={{ height: "100%", width: "100%" }}
              alt="logo"
            />
          </LinkR> */}
          <ul className={styles.navlinkContainer}>
            {scrollLinks.map((scrollLink) => (
              <LinkS
                to={scrollLink}
                smooth
                spy
                spyThrottle={500}
                duration={500}
                className={styles.navlink}
              >
                {scrollLink}
              </LinkS>
            ))}
          </ul>
          <div className={styles.navMenu}>
            <FiMenu
              onClick={() => {
                setSideBarOpen(!sideBarOpen);
              }}
            />
          </div>
        </nav>
      </div>
      <SideBar
        sideBarOpen={sideBarOpen}
        setSideBarOpen={setSideBarOpen}
        scrollLinks={scrollLinks}
      />
    </div>
  );
};

export default Navbar;
